import React, { useState } from 'react';
import { Link } from 'gatsby';
import styles from './navigation.module.css';
import './react-burger.css';
import { slide as Menu } from 'react-burger-menu'

export default (props) => {
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);
  return (
    <React.Fragment>
      <nav role="navigation" className={styles.navigationContainer}>
        <ul className={styles.navigation}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/aktuelles/">Aktuelles</Link>
          </li>
          <li>
            <Link to="/ueber-uns/">Über uns</Link>
          </li>
          <li>
            <Link to="/veranstaltungen/">Veranstaltungen</Link>
          </li>
          <li>
            <Link to="/service/">Service</Link>
          </li>
          <li>
            <Link to="/fachgebiete/">Fachgebiete</Link>
          </li>
        </ul>
      </nav>

      <Menu
        pageWrapId="page-wrap"
        outerContainerId="App"
        isOpen={props.mobileNavIsOpen ? true : mobileNavIsOpen}
        onStateChange={state => setMobileNavIsOpen(state.isOpen)}
        width="300px"
        style={{ zIndex: 99 }}
      >
        <h1>Navigation</h1>
        <Link to="/">Home</Link>
        <Link to="/aktuelles/">Aktuelles</Link>
        <Link to="/ueber-uns/">Über uns</Link>
        <Link to="/veranstaltungen/">Veranstaltungen</Link>
        <Link to="/service/">Service</Link>
        <Link to="/fachgebiete/">Fachgebiete</Link>
        <Link to="/impressum/">Impressum</Link>
      </Menu>
    </React.Fragment>
  )
}

