import React from 'react';
import base from './base.css';
import Container from './container';
import Navigation from './navigation';
import Footer from './footer';

class Template extends React.Component {
  render() {
    const { location, children, noFooter, sponsors } = this.props;
    let hasSponsors = false;
    // console.log(location);
    let header;

    let rootPath = `/`;
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`;
    }
    
    if (sponsors) {
      hasSponsors = sponsors.length > 0 ? true : false;
    }

    return (
      <Container>
        <Navigation mobileNavIsOpen={this.props.mobileNavIsOpen}/>
        {children}
        {!noFooter && <Footer sponsors={sponsors} hasSponsors={hasSponsors} />}
      </Container>
    )
  }
}

export default Template;
