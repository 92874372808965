import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ title, description, article, microdata }) => {
    const { pathname } = useLocation();
    const { site } = useStaticQuery(query);

    const {
        defaultTitle,
        titleTemplate,
        defaultDescription,
        siteUrl,
    } = site.siteMetadata;
    const seo = {
        title: title || defaultTitle,
        description: (description ? defaultDescription : null),
        url: `${siteUrl}${pathname}`,
    };
    return (
        <Helmet title={seo.title} titleTemplate={title && titleTemplate}>
            <html lang="de" />
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && <meta name="description" content={seo.description} />}
            {seo.description && (
                <meta name="twitter:description" content={seo.description} />
            )}
            {seo.description && (
                <meta property="og:description" content={seo.description} />
            )}
            {seo.url && <meta property="og:url" content={seo.url} />}
            {seo.url && <link rel="canonical" href={seo.url} />}
            {(article ? true : null) && <meta property="og:type" content="article" />}
            {microdata && 
                <script type="application/ld+json">
                {`{
                    "@context": "http://schema.org",
                    "@type": "Organization",
                    "@id": "jsv-frohnleiten",
                    "name": "Steirischer Jagdschutzverein Frohnleiten",
                    "url": "https://www.jsv-frohnleiten.at",
                    "legalName": "Steirischer Jagdschutzverein Frohnleiten - Zweigverein des Steirischen Jagdschutzvereines",
                    "description": "${seo.description}",
                    "email": "frohnleiten@jagdschutzverein.at",
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "Hofamt 26",
                        "addressLocality": "Frohnleiten",
                        "addressRegion": "Styria",
                        "postalCode": "8130",
                        "addressCountry": "Austria"
                    },
                    "memberOf": {
                        "@type": "Organization",
                        "name": "Steirischer Jagdschutzverein"
                    },
                    "rating": {
                        "@type": "AggregateRating",
                        "ratingValue": "5",
                        "reviewCount": "7",
                        "itemReviewed": {
                            "@type": "Organization",
                            "@id": "jsv-frohnleiten"
                        }
                    }
                }`}
                </script>
            }
        </Helmet>
    );
};

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    article: PropTypes.bool,
};

SEO.defaultProps = {
    title: null,
    description: null,
    article: false,
};

export default SEO;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
      }
    }
  }
`;