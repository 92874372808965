import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styles from './footer.module.css';

export default ({ sponsors, hasSponsors }) => (
  <footer>
    {hasSponsors && 
      <div className={styles.sponsors}>
        <h2>Partner</h2>
        {sponsors && sponsors.map(({ node }, index) => (
          <a href={node.website} key={index} target="_blank">
            <Img 
              alt={node.name} 
              className={styles.sponsorLogo} 
              fixed={node.logo.fixed} 
              imgStyle={{ objectFit: 'contain' }}
              objectFit="contain" 
            />
          </a>
        ))}
      </div>
    }
    <Link to="/impressum">Impressum & Datenschutz</Link>
    <span className={styles.notice}>&copy; StJSV Zweigverein Frohnleiten</span>
    <span className={styles.notice}>Konzeption, Design und technische Umsetzung von <a href="https://jakobpack.com" target="_blank">jakobpack</a></span>
  </footer>
);
